import {createAction, props} from "@ngrx/store";
import {NotificationDetailDto} from "@dto/notification-detail.dto";
import {WebsocketUpdateDetailDto} from "@dto/websocket-update-detail.dto";
import {NullableType} from "@type/nullable.type";
import {UserAllowedProductIdList} from "@dto/user-allowed-product-id-list";

const state = "[Notification detail]"

export const initNotificationDetail = createAction(
  `${state} Init notification detail`,
  props<{ sourceId: string, userAllowedProductIdList: UserAllowedProductIdList }>()
)

export const initNotificationDetailSuccess = createAction(
  `${state} Init notification detail success`,
  props<{ data: NullableType<NotificationDetailDto> }>()
)

export const initNotificationDetailFailure = createAction(
  `${state} Init notification detail failure`,
  props<{ error: string }>()
)

export const updateNotificationDetail = createAction(
  `${state} Update notification detail`,
  props<{ toUpdate: WebsocketUpdateDetailDto }>()
)
