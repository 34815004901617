import {State} from "@store/state";
import {createSelector} from "@ngrx/store";

const getUserInfos = (state: State) => state.userInfos;

export const getUserAllowedProductId = createSelector(
  getUserInfos,
  (state) => state.allowedProductIdList
);

export const getUserAllowedProductIdList = createSelector(
  getUserAllowedProductId,
  (state) => state.data
);

export const getAllowedProductIdListIsLoading = createSelector(
  getUserAllowedProductId,
  (state) => state.isLoading
);

export const getAllowedProductIdListFetchIsError = createSelector(
  getUserAllowedProductId,
  (state) => state.error
);

